function states($stateProvider) {
  'ngInject';
  $stateProvider
    .state('security', {
      parent: 'root',
      url: '/security',
      redirectTo: '.main',
      template: '<ui-view></ui-view>',
      permissions: {
        only: 'isEmbeddedMobile',
        redirectTo: 'dashboard'
      },
      data: {
        gaLocation: 'security'
      },
      resolve: {
        module: ($ocLazyLoad) => {
          'ngInject';
          return import(/* webpackChunkName: "acp.section.security" */ 'apps/security').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('security.main', {
      controller: 'AcpSecuritySectionCtrl',
      templateUrl: 'apps/security/templates/acp-security.ng.html'
    })
    .state('security.biometrics-enrollment', {
      template: '',
      controller: 'AcpBiometricsEnrollmentCtrl'
    });
}

export default states;
